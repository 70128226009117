import {Inject, Injectable, PLATFORM_ID} from '@angular/core'
import {InMemoryStorage, StorageService} from './storage.service'
import {isPlatformBrowser} from '@angular/common'
import {Logger} from '../logging/logger.types'

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService extends StorageService {
  constructor(@Inject(PLATFORM_ID) platformId: Record<string, unknown>, logger: Logger) {
    super(isPlatformBrowser(platformId) ? window.sessionStorage : new InMemoryStorage(), logger)
  }
}
